import { Link, NavLink } from 'react-router-dom';
import logo from '../assests/bsbds.jpg'
import { useEffect, useState } from 'react';

function Navbar() {

    const [isSticky, setSticky] = useState(false);
    const [navExpand, setNavExpand] = useState(false);

    const handleScroll = () => {
        if (window.scrollY > 400) {
            setSticky(true);
        } else {
            setSticky(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const navbarToggle = () => {
        setNavExpand(!navExpand);
    }

    return (
        <div>
            <div className='d-flex justify-content-around align-items-center' style={{ height: "90px" }}>
                <div>
                    <img className='rounded' src={logo} width={100} alt="" />
                </div>
                <div>
                    <div className='headerContact'>
                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone" viewBox="0 0 16 16">
                        <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                    </svg> */}

                        <span className='m-2 headerContactInfo'>
                            <a className="text-decoration-none" style={{ color: "#7f0000" }} href="tel:9463327041">
                                <i className="fas fa-phone me-2"></i>
                                9463327041, 
                            </a>
                        </span>
                        <span className='m-2 headerContactInfo'>
                            <a className="text-decoration-none" style={{ color: "#7f0000" }} href="tel:9463473363">
                                9463473363
                            </a>
                        </span>
                    </div>
                    <div className='headerContact'>
                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
                        <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z" />
                    </svg> */}
                        <span className='m-2 headerContactInfo'>
                            <a className="text-decoration-none" style={{ color: "#7f0000" }} href="mailto:bsbdsmpssschool@gmail.com">
                                <i className="fas fa-envelope me-2"></i>
                                bsbdsmpssschool@gmail.com
                            </a>

                        </span>
                    </div>
                </div>
            </div>
            <nav className={`navbar navbar-expand-lg navbar-dark ${isSticky ? 'sticky' : ''}`} style={{ backgroundColor: "#301515f0" }}>
                <div className="container-fluid">
                    {/* <a className="navbar-brand" href="#">Navbar</a> */}
                    <button onClick={navbarToggle} className="navbar-toggler" type="button" data-bs-toggle="collapse" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className={`collapse navbar-collapse justify-content-center ${navExpand ? 'show' : ""}`} id="navbarNavDropdown">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                {/* <Link to="/" className="nav-link active" aria-current="page">Home</Link> */}
                                <NavLink to="/" exact className="nav-link" activeClassName="active">
                                    Home
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/about" className="nav-link" activeClassName="active">
                                    About
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/allEvents" className="nav-link" activeClassName="active">All Events</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/facilities" className="nav-link" activeClassName="active">Facilities</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/admission" className="nav-link" activeClassName="active">Admission</NavLink>
                            </li>
                            <NavLink to="/dashboard/list/events" className="nav-link" activeClassName="active">Dashboard</NavLink>

                            {/* <li className="nav-item">
                                <NavLink to="/dashboard/list/events" className="nav-link" activeClassName="active">Dashboard</NavLink>
                            </li> */}
                            {/* <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Dropdown link
                                </a> */}
                            {/* <ul className="dropdown-menu">
                                    <li><a className="dropdown-item" href="#">Action</a></li>
                                    <li><a className="dropdown-item" href="#">Another action</a></li>
                                    <li><a className="dropdown-item" href="#">Something else here</a></li>
                                </ul> */}
                            {/* </li> */}
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    );
}

export default Navbar;
