import React from 'react'
import BackgroundImg from '../components/BackgroundImg'
import AboutDetails from '../components/AboutDetails'
import AboutMembers from '../components/AboutMembers'

function AboutUs() {
  return (
    <div>
        <BackgroundImg title="About"/>
        <AboutDetails/>
        <AboutMembers/>
    </div>
  )
}

export default AboutUs