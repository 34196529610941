import axios from 'axios';
import React, { useState } from 'react'
const APP_URL = process.env.REACT_APP_BACKEND_URL;

function ProfileUpdate() {
    const [alertMssg, setaAlertMssg] = useState('');
    const [alert, setAlert] = useState(false);
    const [updatePass, setUpdatePass] = React.useState({
        password: "",
        confirm_password: ""
    })
    const handleChange = (e) => {
        const { name, value } = e.target;
        setUpdatePass((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (updatePass.password === updatePass.confirm_password) {
            const updatesPass = {
                password : updatePass.password
            }
            try {

                const res = await axios.put(`${APP_URL}/school/update_password`, updatesPass);
                if (res.status == 200) {

                    setaAlertMssg(res.data.message)
                    setAlert(true);
                    setTimeout(() => {
                        setAlert(false)
                        window.location.reload();
                    }, [2500]);

                    localStorage.removeItem('userData');
                }
            } catch (error) {
                console.log(error);
                setAlert(true)
                setaAlertMssg(error.response.data.message)
                setTimeout(() => {
                    setAlert(false)
                }, [2500])
            }
        } else {
                setAlert(true)
                setaAlertMssg("Passwords do not match")
                setTimeout(() => {
                    setAlert(false)
                }, [2500])
         }

    }
    return (
        <>
            {
                alert && (
                    <div className="alert alert-info text-center" role="alert" style={{ zIndex: "1" }}>
                        {alertMssg}
                    </div>
                )
            }

            <section className="vh-100" style={{ backgroundClip: '#508bfc' }}>
                <div className="container py-5 h-100">
                    <div className="row d-flex justify-content-center align-items-center h-100">
                        <div className="col-12 col-md-8 col-lg-6 col-xl-5">
                            <div className="shadow-2-strong" style={{ borderRadius: '1rem' }}>
                                <div className="card-body p-5 text-center">

                                    <h3 className="mb-5">Update Password</h3>
                                    <form action="" onSubmit={handleSubmit}>

                                        <div className="form-outline mb-4">
                                            <input type="text" id="typePasswordX-2" name='password' value={updatePass.password} onChange={handleChange} className="form-control form-control-lg" required />
                                            <label className="form-label" for="typePasswordX-2">Password</label>
                                        </div>

                                        <div className="form-outline mb-4">
                                            <input type="text" id="typePasswordX-3" name='confirm_password' value={updatePass.confirm_password} onChange={handleChange} className="form-control form-control-lg" required />
                                            <label className="form-label" for="typePasswordX-3">Confirm Password</label>
                                        </div>

                                        <button className="btn btn-primary btn-lg btn-block w-100" type="submit">Update</button>
                                    </form>
                                    <hr className="my-4" />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ProfileUpdate