import React from 'react'
import imgEvent from '../assests/science_lab.jpeg'
import imgEventSec from '../assests/event-bg.jpeg'


function BackgroundImg(props) {
  return (
    <div>
        <div
        className="bg-image d-flex justify-content-center align-items-center event-bg"
        style={{ backgroundImage: `url(${props.eventImg ? imgEventSec : imgEvent})` }}
      >
      </div>
      {/* <h1 className="text-white event-heading">{specificEvent[0]?.title}</h1> */}
      <h1 className="text-white event-heading">{props.title}</h1>
    </div>
  )
}

export default BackgroundImg