import React from 'react'
import schlImg from '../assests/building.png'

function WelcomeSection() {
  return (
    <div className='welcomeSectionContainer mt-4'>
        <div className='welcomeTextDetail'>
            <h3>Welcome to B.S.B.D.S.M.P. SCHOOL</h3>
            <p>
            Bhai Sahib Bhai Daya Singh Memorial Public Sen Sec. School welcomes you to our educational haven! Rooted in a tradition of academic excellence and community spirit, our school is committed to nurturing the holistic development of each student. At BSBDSMP, named in honor of Bhai Sahib Bhai Daya Singh, we take pride in providing a supportive and inclusive environment where students can flourish academically, socially, and morally. Our dedicated educators strive to ignite a passion for learning, while our commitment to embracing innovation prepares students for the challenges of the future. Explore our website to discover the distinctive programs, vibrant activities, and the sense of camaraderie that make BSBDSMP a beacon of educational achievement and personal growth. Join us as we embark on a journey of knowledge, character-building, and success!
            </p>
        </div>
        <div className='welcomeImage'>
            <img className='rounded' src={schlImg} alt="" />
        </div>
    </div>
  )
}

export default WelcomeSection