import React, { useEffect, useState } from 'react'
import BackgroundImg from '../components/BackgroundImg'
import img from '../assests/admission_pemphlate.jpeg'
import { getPemphlet } from '../services/AllApies';
const APP_URL = process.env.REACT_APP_BACKEND_URL;

function Admission() {
  const [pemphlet,setPemphlet] = useState([])

  const fetchPemphlet = async () => {
    try {
      const response = await getPemphlet();
      if (response.status == 200) {
        setPemphlet(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchPemphlet();
  }, []);
  
  return (
    <div>
        <BackgroundImg title="Admissions"/>
        <div className="m-5 admissions-wrapper">
            <p className='text-center'>Rgistration for admission will done in the month of january on receiving the registration-cum-admission forms (non refundable). This fee may vary from year to year</p>
        </div>
        <div className='admission-pemphlate'>
            <img src={`${APP_URL}/uploads/${pemphlet[0]?.pamphlet_img}`} alt="" />
        </div>
        {/* <iframe src="https://www.africau.edu/images/default/sample.pdf  " width={300}></iframe> */}
    </div>
  )
}

export default Admission