import React from 'react'

function YouTubeVid() {
  return (
    <div className='video-sec d-flex flex-wrap justify-content-center mt-5' style={{gap:"30px"}}>
        <div className="first-video">
        <iframe src="https://www.youtube.com/embed/vH1IYihhGIg?si=BQi_Q7iNkpq941Lo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
        <div className="sec-video">
        <iframe src="https://www.youtube.com/embed/wA1LriO_jVg?si=PLupfuQ3gthchqUX" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
    </div>
  )
}

export default YouTubeVid