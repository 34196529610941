import React from 'react'
import scienceLab from '../assests/science_lab.jpeg'
import comLab from '../assests/computer_lab.jpeg'
import musicClass from '../assests/music_class.jpeg'
import sports from '../assests/sports.jpeg'
import transport from '../assests/transport.jpeg'
import BackgroundImg from '../components/BackgroundImg'

function Facilities() {
    return (
        <>
            <BackgroundImg title="Facilities & Activities"/> 
        <div className="container">
            
        <div className='custom-facilities-card d-flex justify-content-around flex-wrap mt-5' style={{gap:"30px"}}>
            <div class="card" style={{ position:"static"}}>
                <img src={scienceLab} class="card-img-top" alt="..." />
                <div class="card-body">
                    <h5 class="card-title text-uppercase">Science Lab</h5>
                    <p class="card-text">
                    Step into our cutting-edge science lab, where curiosity meets discovery. Equipped with state-of-the-art facilities, students explore the wonders of science through hands-on experiments, cultivating a passion for inquiry and innovation.
                    </p>
                    
                </div>
            </div>
            <div class="card" style={{ position:"static"}}>
                <img src={comLab} class="card-img-top" alt="..." />
                <div class="card-body">
                    <h5 class="card-title text-uppercase">Computer Lab</h5>
                    <p class="card-text">
                    Explore the world of technology in our modern computer lab, where students engage in hands-on learning, coding, and digital exploration. Equipped with the latest technology, our lab fosters digital literacy and innovation.
                    </p>
                    
                </div>
            </div>
            <div class="card" style={{ position:"static"}}>
                <img src={musicClass} class="card-img-top" alt="..." />
                <div class="card-body">
                    <h5 class="card-title text-uppercase">Music Classes</h5>
                    <p class="card-text">
                    Harmony comes to life in our music classes, where students discover the joy of melody and rhythm. From instrumental mastery to vocal expression, our music program nurtures creativity and a lifelong appreciation for the art of sound.
                    </p>
                    
                </div>
            </div>
            <div class="card" style={{ position:"static"}}>
                <img src={sports} class="card-img-top" alt="..." />
                <div class="card-body">
                    <h5 class="card-title text-uppercase">Sports</h5>
                    <p class="card-text">
                    Dive into the spirit of athleticism at our school, where sports transcend boundaries and cultivate teamwork. From spirited competitions to physical fitness, our sports program instills values of discipline, resilience, and a passion for healthy living.
                    </p>
                    
                </div>
            </div>
            <div class="card" style={{ position:"static"}}>
                <img src={transport} class="card-img-top" alt="..." />
                <div class="card-body">
                    <h5 class="card-title text-uppercase">Transport</h5>
                    <p class="card-text">
                    Experience convenience and safety with our dedicated school bus service. From door-to-door pickups to a secure journey, our transportation ensures students arrive ready for a day of learning in comfort and peace of mind.
                    </p>
                    
                </div>
            </div>
        </div>
        </div>
        </>
    )
}

export default Facilities