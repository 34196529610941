import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { getPemphlet } from '../services/AllApies';
const APP_URL = process.env.REACT_APP_BACKEND_URL;

function UpdatePamphlet() {
    const [file, setFile] = useState();
    const [alertMssg, setaAlertMssg] = useState('');
    const [alert, setAlert] = useState(false);
    const [pemphlet,setPemphlet] = useState([])
    const [render,setRender] = useState(false)


    const handleFile = (e) => {
        setFile(e.target.files[0]);
    }

    const uploadFileHandler = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('image', file);

        try {
            
            const res = await axios.post(`${APP_URL}/school/upload_pemphlet`, formData);
            // console.log(data,"data1");
            if (res.status == 200) {

                setaAlertMssg(res.data.message)
                setAlert(true);
                setTimeout(() => {
                    setAlert(false)
                    window.location.reload();
                }, [2500]);
                // navigate("/dashboard/list/events")
                // setRender(!render);
            }
        } catch (error) {
            console.log(error);
            setAlert(true)
            setaAlertMssg(error.response.data.message)
            setTimeout(() => {
                setAlert(false)
            }, [2500])
        }
    }

    const fetchPemphlet = async () => {
        try {
          const response = await getPemphlet();
          if (response.status == 200) {
            setPemphlet(response.data.data);
            // window.location.reload();
          }
        } catch (error) {
          console.log(error);
        }
      }
    
      useEffect(() => {
        fetchPemphlet();
      }, []);

    return (
        <>
        {
        alert && (
          <div class="alert alert-info text-center" role="alert">
           {alertMssg}
          </div>
        )
      }
        <div className='container-fluid mt-5 events-update-container p-4' style={{ backgroundColor: "gainsboro" }}>
            <div className='mb-5 font-weight-bold'>
                <h3>Update Pamphlet</h3>
            </div>
            <div className='d-flex align-items-center justify-content-between flex-wrap' style={{gap:"20px"}}>
            <form action="" onSubmit={uploadFileHandler}>
            <input type="file" className="form-control-file" id="exampleFormControlFile2" onChange={handleFile} required/>
            <button type="submit" className="btn btn-outline-primary">Submit</button>
            </form>
            <div>
            {pemphlet.length > 0 && (
            <img src={`${APP_URL}/uploads/${pemphlet[0]?.pamphlet_img}`} width={200} alt="" />
            )}
            </div>
            </div>
        </div>
        </>
    )
}

export default UpdatePamphlet