import '../App.css';
import React, { useContext, useState } from 'react'
import { MyContext } from '../contextApi/loginState'
import { Navigate, useNavigate } from 'react-router-dom';


function Login() {
  const { login } = useContext(MyContext);
  const navigate = useNavigate()
  const [alertMssg, setaAlertMssg] = useState('');
  const [alert, setAlert] = useState(false);
  const [loginState, setLoginState] = React.useState({
    email: "",
    password: ""
  })
  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginState((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault()

    if (loginState.email == '' || loginState.password == '') {
      // setAlert('Fill all required fields')
      // setOpen(true)
      return;
    }
    const response = await login(loginState);
    if (response?.status == 200) {
      navigate('/dashboard/list/events')
    }
    setAlert(true);
    setaAlertMssg(response);
    setTimeout(()=>{
      setAlert(false)
    },[2500])
  };
  return (
    <>
      {
        alert && (
          <div className="alert alert-info text-center" role="alert" style={{ zIndex: "1" }}>
            {alertMssg}
          </div>
        )
      }

        {/* <input type="email" id="typeEmailX" className="form-control form-control-lg" name='email' value={loginState.email} onChange={handleChange}/> */}

        {/* <input type="password" id="typePasswordX" className="form-control form-control-lg" name='password' value={loginState.password} onChange={handleChange}/> */}
        <section className="vh-100" style={{backgroundClip: '#508bfc'}}>
          <div className="container py-5 h-100">
            <div className="row d-flex justify-content-center align-items-center h-100">
              <div className="col-12 col-md-8 col-lg-6 col-xl-5">
                <div className="shadow-2-strong" style={{borderRadius: '1rem'}}>
                  <div className="card-body p-5 text-center">

                    <h3 className="mb-5">Sign in</h3>
            <form action="" onSubmit={handleSubmit}>
                    <div className="form-outline mb-4">
                      <input type="email" id="typeEmailX-2" name='email' value={loginState.email} onChange={handleChange} className="form-control form-control-lg" required/>
                      <label className="form-label" for="typeEmailX-2">Email</label>
                    </div>

                    <div className="form-outline mb-4">
                      <input type="password" id="typePasswordX-2" name='password' value={loginState.password} onChange={handleChange} className="form-control form-control-lg" required/>
                      <label className="form-label" for="typePasswordX-2">Password</label>
                    </div>
{/* 
                    <div className="form-check d-flex justify-content-start mb-4">
                      <input className="form-check-input" type="checkbox" value="" id="form1Example3" />
                      <label className="form-check-label" for="form1Example3"> Remember password </label>
                    </div> */}

                    <button className="btn btn-primary btn-lg btn-block w-100" type="submit">Login</button>
                    </form>
                    <hr className="my-4" />

                    {/* <button className="btn btn-lg btn-block btn-primary" style={{backgroundColor: "#dd4b39"}}
                      type="submit"><i className="fab fa-google me-2"></i> Sign in with google</button>
                    <button className="btn btn-lg btn-block btn-primary mb-2" style={{backgroundColor: "#3b5998"}}
                      type="submit"><i className="fab fa-facebook-f me-2"></i>Sign in with facebook</button> */}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
    </>
  )
}

export default Login