import React from 'react'
import chairperson from '../assests/chairperson.png' 
import genSecy from '../assests/gen_scry.png' 

function AboutMembers() {
  return (
    <div className='container membersContainer p-4 mt-4'>
        <div className='d-flex justify-content-around'style={{gap:"20px"}}>
            <div className='firstImg membersImages'>
        <img src={chairperson} alt="" />
            </div>
            <div className="secImg membersImages">
        <img src={genSecy} alt="" />
            </div>
        {/* <img src="" alt="" /> */}
        </div>
    </div>
  )
}

export default AboutMembers