import React, { useEffect, useState } from 'react'
import schlImg from '../assests/Screenshot 2023-12-02 202204.png'
import EventImages from '../components/EventImages'
import {getEvents} from '../services/AllApies';
import { useParams } from 'react-router-dom';
import BackgroundImg from '../components/BackgroundImg';
const APP_URL = process.env.REACT_APP_BACKEND_URL;

function SpecificEvent() {
const [specificEvent, setSpecificEvent] = useState([]);
const param = useParams();

const fetchSpecificEvent = async()=>{
        try {
            const response = await getEvents(param.id);
            if (response.status == 200) {
              setSpecificEvent(response.data.data);
            }
        } catch (error) {
            console.log(error);
        }
    }
    
    useEffect(()=>{
      fetchSpecificEvent();
  },[]);

  return (
    <div className='specific-event-container'>
      <BackgroundImg eventImg="true" title={specificEvent[0]?.title}/>
      <div className='welcomeSectionContainer'>
        <div className='welcomeTextDetail'>
          <p>{specificEvent[0]?.description}</p>
        </div>
        <div className='welcomeImage'>
          <img className='rounded' src={`${APP_URL}/uploads/${specificEvent[0]?.main_image}`} alt="" />
        </div>
      </div>
      <EventImages specificEvent={specificEvent}/>
    </div>

  )
}

export default SpecificEvent