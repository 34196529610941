import React, { useEffect, useState } from 'react'
import BackgroundImg from '../components/BackgroundImg';
import {getAllEvents} from '../services/AllApies';
import EventImages from '../components/EventImages';
import { useNavigate } from 'react-router-dom';
const APP_URL = process.env.REACT_APP_BACKEND_URL;

function AllEvents() {
const [allEvents, setAllEvents] = useState([]);
const navigate = useNavigate();

const fetchSpecificEvent = async()=>{
        try {
            const response = await getAllEvents();
            if (response.status == 200) {
                setAllEvents(response.data.data);
            }
        } catch (error) {
            console.log(error);
        }
    }
    
    useEffect(()=>{
      fetchSpecificEvent();
  },[]);
  return (
    <div>
        <BackgroundImg eventImg="true" title="All Events"/>
        {/* <EventImages specificEvent={allEvents}/> */}
        <div className='latestEventsContainer container'>
    {/* <h2 className='text-center mb-5' style={{fontWeight:"700"}}>Latest Events</h2> */}
    <div className='latestEventswrapper d-flex justify-content-center flex-wrap' style={{gap:'50px'}}>
        {
            allEvents?.map((item)=>(
                <div className='latestEventsImgCont' onClick={()=>navigate(`/event/${item.id}`)} key={item.id} style={{cursor:"pointer"}}>
                    <img className='rounded' src={`${APP_URL}/uploads/${item.main_image}`} width={340} alt="" />
                    <p>{item.title}</p>
                </div>
            ))
        }
    </div>
    </div>
    </div>
  )
}

export default AllEvents