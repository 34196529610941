import React from 'react'

function ImageOverText() {
    return (
        <div className='mt-5'>
            <div className="col-md-4q col-xs-12q">
                <div className="bg-image  custom-bg">
                    <div className="overlay-white"></div>
                </div>
                <div className='d-flex justify-content-center align-items-center'>
                    <p className='text-white para-bg rounded'>
                        <span className="overlay-title">prosperity with education & peace of mind with moral values under light of gurbani</span><br/>
                            {/* <span className="overlay-subtext">This is subtext.</span> */}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default ImageOverText