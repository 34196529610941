import React from 'react'
import imgIst from '../assests/school_buses.jpeg';
import schlImg from '../assests/bhangra_boys.jpeg';
// import schlGround from '../assests/building.png';
import schlGround from '../assests/schl_ground2.jpeg';
import childImg from '../assests/WhatsApp Image 2023-12-04 at 6.24.40 PM.jpeg';


function MainCarousel() {
  return (
    <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
  <div className="carousel-inner">
  <div className="carousel-item active">
      <img src={schlImg} className="d-block w-100" alt="..."/>
      <div className="carousel-caption d-md-block">
        <div>
        <h3>Shaping Futures with Passion and Dedication</h3>
        <p>Committed educators at our school inspire students with passion and dedication, fostering a love for learning and shaping futures.</p>
        </div>
      </div>
    </div>
    <div className="carousel-item">
      <img src={imgIst} className="d-block w-100" alt="..."/>
      <div className="carousel-caption d-md-block">
        <div>
        <h3>Safe and Convenient Bus Service</h3>
        <p>Trust us to provide a reliable and comfortable transportation experience, ensuring peace of mind for both students and parents alike.</p>
        </div>
      </div>
    </div>
    <div className="carousel-item">
      <img src={schlGround} className="d-block w-100" alt="..."/>
      <div className="carousel-caption d-md-block d-flex justify-content-center">
        <div>
        <h3>location & campus building</h3>
        <p>
The Patiala Rajpura Bypass, spread across two acres, houses a highly impactful building situated amidst an open and natural environment.</p>
</div>
      </div>
    </div>
    {/* <div className="carousel-item">
      <img src={childImg} className="d-block w-100" alt="..."/>
      <div className="carousel-caption d-md-block">
        <div>
        <h3>Shaping Futures with Passion and Dedication</h3>
        <p>Committed educators at our school inspire students with passion and dedication, fostering a love for learning and shaping futures.</p>
        </div>
      </div>
    </div> */}
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>
  )
}

export default MainCarousel