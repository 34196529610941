// import React, { useState } from 'react';
// import Sidebar from '../components/Sidebar';
// import { Link, Outlet, useNavigate } from 'react-router-dom';
// import dashboardImg from '../assests/school-dashboard-removebg-preview.png'

// function Dashboard() {
//   const [active, setactive] = useState({
//     events: true,
//     pamphlet:false,
//     profile:false
//   })

//   const handlePamphlet = ()=>{
//     setactive({
//     events: false,
//     pamphlet:true
//     })
//   }
// const handleEvents  = ()=>{
//   setactive({
//     events: true,
//     pamphlet:false
//     })
// }
//   return (
//     <div className='d-flex dashboard-container'>
//       <div className="sidebar">
//         <Link className={`${active.events?"active":""}`} to="list/events" onClick={handleEvents}>Events</Link>
//         <Link className={`${active.pamphlet?"active":""}`} onClick={handlePamphlet} to="updatepamphlet">Admission Pamphlet</Link>
//         {/* <Link className={`${active.profile?"active":""}`} onClick={handlePamphlet} to="updatepamphlet">Profile</Link> */}
        
//       </div>

//       <div className="col py-3">
//             <Outlet/>
//         </div>
//     </div>
//   )
// }

// export default Dashboard


import React, { useState } from 'react';
import { Link, Outlet } from 'react-router-dom';

const NavLink = ({ to, label, isActive, onClick }) => (
  <Link className={isActive ? 'active' : ''} to={to} onClick={onClick}>
    {label}
  </Link>
);

function Dashboard() {
  const [activeTab, setActiveTab] = useState('events');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="d-flex dashboard-container">
      <div className="sidebar">
        <NavLink to="list/events" label="Events" isActive={activeTab === 'events'} onClick={() => handleTabClick('events')} />
        <NavLink to="updatepamphlet" label="Admission Pamphlet" isActive={activeTab === 'updatepamphlet'} onClick={() => handleTabClick('updatepamphlet')} />
        {/* Uncomment the following line if you need a "Profile" link */}
        <NavLink to="profile" label="Profile" isActive={activeTab === 'profile'} onClick={() => handleTabClick('profile')} />
      </div>

      <div className="col py-3">
        <Outlet />
      </div>
    </div>
  );
}

export default Dashboard;
