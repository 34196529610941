import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {getEvents} from '../services/AllApies';
const APP_URL = process.env.REACT_APP_BACKEND_URL;

function LatestEvents() {
    const navigate = useNavigate();
    const [latestEvents, setLatestEvents] = useState([]);

    const fetchLatestEvents = async()=>{
        try {
            const response = await getEvents();
            if (response.status == 200) {
                setLatestEvents(response.data.data);
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(()=>{
        fetchLatestEvents();
    },[]);

  return (
    <div className='latestEventsContainer'>
    <h2 className='text-center mb-4' style={{fontWeight:"700"}}>Latest Events</h2>
    <div className='latestEventswrapper d-flex justify-content-center flex-wrap' style={{gap:'50px'}}>
        {
            latestEvents?.map((item)=>(
                <div className='latestEventsImgCont' onClick={()=>navigate(`event/${item.id}`)} key={item.id} style={{cursor:"pointer"}}>
                    <img className='rounded' src={`${APP_URL}/uploads/${item.main_image}`} width={340} alt="" />
                    <p>{item.title}</p>
                </div>
            ))
        }
    </div>
    </div>
  )
}

export default LatestEvents