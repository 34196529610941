import axios from "axios";
const APP_URL = process.env.REACT_APP_BACKEND_URL;

export const getEvents = async(id)=>{
    const response = await axios.get(`${APP_URL}/school/get_events?id=${id}`);
    return response;
}
export const getAllEvents = async()=>{
    const response = await axios.get(`${APP_URL}/school/get_all_events`);
    return response;
}
export const deleteEvent = async(id)=>{
    const response = await axios.get(`${APP_URL}/school/delete_event?id=${id}`);
    return response;
}
export const getPemphlet = async()=>{
    const response = await axios.get(`${APP_URL}/school/get_pemphlet`);
    return response;
}