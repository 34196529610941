import React, { useEffect } from 'react'
const APP_URL = process.env.REACT_APP_BACKEND_URL;

function EventImages({specificEvent}) {
    let multipleImages = null;
    if (specificEvent[0]?.multiple_images) {
        multipleImages = JSON.parse(specificEvent[0]?.multiple_images);
    }
  return (
    <div className='d-flex justify-content-center'>
    <div className='latestEventswrapper d-flex justify-content-center flex-wrap' style={{gap:'50px',width:'80vw'}}>
        {
            multipleImages?.map((itm)=>{
                return(
                    <div className="latestEventsImgCont">
                    <img className='rounded' src={`${APP_URL}/uploads/${itm}`} alt="" />
                    </div>
                )
            })
        }
    </div>
    </div>
  )
}

export default EventImages