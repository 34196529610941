import axios from 'axios';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
const APP_URL = process.env.REACT_APP_BACKEND_URL;

function EventsUpdate() {
  const [file, setFile] = useState();
  const [multiFile, setMultiFile] = useState([]);
  const [title, setTitle] = useState('');
  const [desc, setDesc] = useState('');
  const [alertMssg, setaAlertMssg] = useState('');
  const [alert, setAlert] = useState(false);

  const navigate = useNavigate();
  const handleFile = (e) => {
    setFile(e.target.files[0]);
  }

  const handleFileMultiple = (e)=>{
    setMultiFile(e.target.files);
  }

  const handleChangeText = (e) => {
    const { id, value } = e.target;

    if (id === 'heading') {
      setTitle(value);
    } else if (id === 'exampleFormControlTextarea1') {
      setDesc(value);
    }
  }

  const uploadFileHandler = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('image', file);
    formData.append('title', title);
    formData.append('description', desc);
    
  
  try {
    const multipleFilesArray = Array.from(multiFile);
    const res = await axios.post(`${APP_URL}/school/upload_single`, formData);
    if(res.status == 200){
      const formData2 = new FormData();
      if(multipleFilesArray.length > 1){
        const id = res.data.data;
        multipleFilesArray?.forEach((file) => {
          formData2.append('multipleImages', file);
        });
        const data = await axios.post(`${APP_URL}/school/upload_multiple?id=${id}`, formData2);
      }
      setaAlertMssg(res.data.message)
      setAlert(true);
      setTimeout(()=>{
        setAlert(false)
      },[2500]);
      navigate("/dashboard/list/events")

    }
    } catch (error) {
      console.log(error);
      setAlert(true)
      setaAlertMssg(error.response.data.message)
      setTimeout(()=>{
        setAlert(false)
      },[2500])
    }
  }

  return (
    <>
    {
        alert && (
          <div class="alert alert-info text-center" role="alert">
           {alertMssg}
          </div>
        )
      }
    <div className='container-fluid mt-5 events-update-container p-4' style={{ backgroundColor: "gainsboro" }}>
      <div className='mb-5 font-weight-bold'>
        <h3>Create Event</h3>
      </div>
      <form onSubmit={uploadFileHandler}>
        <div className="form-group">
          <label htmlFor="heading">Event Name</label>
          <input className="form-control" id='heading' type="text" placeholder="Diwali" required onChange={handleChangeText}></input>
        </div>
        <div className="form-group mt-3">
          <label htmlFor="exampleFormControlTextarea1">Event Description</label>
          <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" onChange={handleChangeText} required></textarea>
        </div>
        <div className="form-group mt-3">
          <label className='mt-4' style={{ marginRight: "30px" }} htmlFor="exampleFormControlFile1">Event Image</label>
          <input type="file" className="form-control-file" id="exampleFormControlFile1" onChange={handleFile} required />
          <div>
          <label className='mt-4' style={{ marginRight: "30px" }} htmlFor="exampleFormControlFile2">Other Event Images</label>
          <input type="file" className="form-control-file" id="exampleFormControlFile2" onChange={handleFileMultiple} multiple/>
          </div>
          <button type="submit" className="btn btn-outline-primary">Submit</button>
        </div>
      </form>
    </div>
    </>
  )
}

export default EventsUpdate