import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { deleteEvent, getAllEvents } from '../services/AllApies';
import { ToastContainer, toast } from 'react-toastify';

function ListEvents() {
  const [allEvents, setAllEvents] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [eventId, setEventID] = useState(null);
  const [alert, setAlert] = useState(false);
  const [reRender, setReRender] = useState(false);
  const [alertMssg, setaAlertMssg] = useState('');
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState('');

  const fetchAllEvents = async () => {
    try {
      const response = await getAllEvents();
      if (response.status == 200) {
        setAllEvents(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const deleteEventById = async () => {
    setShowModal(false);
    try {
      const response = await deleteEvent(eventId);
      if (response.status == 200) {
        setaAlertMssg(response.data.message)
        setAlert(true)
        setReRender(!reRender)
        setTimeout(()=>{
          setAlert(false)
        },[2500])
      }
    } catch (error) {
      console.log(error);
      setAlert(true)
      setaAlertMssg(error.response.data.message)
      setTimeout(()=>{
        setAlert(false)
      },[2500])
    }
  }

  const dateFormater = (inputDateString) => {
    var inputDate = new Date(inputDateString);

    // Format the Date object as desired
    var options = { day: 'numeric', month: 'long', year: 'numeric' };
    var outputDateString = inputDate.toLocaleDateString('en-US', options);
    return outputDateString;
  }

  const handleModal = (id) => {
    setShowModal(true);
    setEventID(id);
    // toast.success('🦄 Wow so easy!', {
    //   position: "top-center",
    //   autoClose: 5000
    //   });

  }

  useEffect(() => {
    fetchAllEvents();
  }, [reRender]);

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    let filteredEvents = null;
    if (searchTerm.trim() === '') {
      // If searchTerm is empty, show all events
      setReRender(!reRender);
    } else {
      // If searchTerm is not empty, filter the events
       filteredEvents = allEvents.filter((event) =>
        event.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
      
    }
    setAllEvents(filteredEvents);
  };

  const handleLogout = ()=>{
    localStorage.removeItem('userData');
    navigate("/login");
  }

  return (
    <>
      {
        alert && (
          <div class="alert alert-info text-center" role="alert">
           {alertMssg}
          </div>
        )
      }
      <div className='container mt-4'>
        <nav className="navbar navbar-light bg-light p-3 justify-content-between">
          <form className="form-inline d-flex" style={{ gap: "15px" }}>
            <input className="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search" onChange={handleChange}/>
            <button className="btn btn-outline-success my-2 my-sm-0" type="submit" onClick={handleSearch}>Search</button>
          </form>
          <button type="button" onClick={handleLogout} className="btn btn-outline-danger">Logout</button>
          <button type="button" onClick={() => navigate('/dashboard/create/events')} className="btn btn-outline-primary">Create Event +</button>
        </nav>
        <div >
          <h3 className='mt-5'>All Events</h3>
          <div style={{ height:"70vh", overflowY:"scroll"}}>
          <table className="table table-hover">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Title</th>
                <th scope="col">Created at</th>
                <th scope="col">Updated at</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {
                allEvents?.length > 0 ?
                allEvents?.map((event, i) => {
                  return (
                    <tr key={i}>
                      <th scope="row">{i + 1}</th>
                      <td className='text-capitalize'>{event.title}</td>
                      <td>{dateFormater(event.created_at)}</td>
                      <td>{dateFormater(event.updated_at)}</td>
                      <td>
                        <span onClick={() => handleModal(event.id)} >
                          <i className="fas fa-trash" style={{ marginRight: "20px" }}></i>
                        </span>
                        <span onClick={() => navigate(`/event/${event.id}`)}>
                        <i className="far fa-eye"></i>
                        </span>

                      </td>

                    </tr>
                  )
                }) : <p className='text-center'>No records</p>
              }
            </tbody>
          </table>
          </div>
        </div>
      </div>

      {showModal && (
        <div class="card">
          <div class="card-body">
            <h5 class="card-title m-3">Are You Sure!</h5>
            {/* <h6 class="card-subtitle mb-2 text-muted">Card subtitle</h6> */}
            {/* <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p> */}
            <div className='d-flex justify-content- m-3' style={{ gap: '20px' }}>
              <button type="button" class="btn btn-outline-danger" onClick={() => setShowModal(false)}>Clear</button>
              <button type="button" class="btn btn-outline-success" onClick={deleteEventById}>Delete</button>
            </div>
          </div>
        </div>
      )}

    </>
  )
}

export default ListEvents