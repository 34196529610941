
import './App.css';
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom";
import Navbar from './components/Navbar';
import Home from './pages/Home';
import SpecificEvent from './pages/SpecificEvent';
import Dashboard from './pages/Dashboard';
// import jwt_decode from 'jwt-decode';
import jwt_decode from "jwt-decode";
// const jwtDecode = require('jwt-decode');

import Login from './pages/Login';
import EventsUpdate from './pages/EventsUpdate';
import ListEvents from './pages/ListEvents';
import Footer from './components/Footer';
import AboutUs from './pages/AboutUs';
import AllEvents from './pages/AllEvents';
import Facilities from './pages/Facilities';
import Admission from './pages/Admission';
import UpdatePamphlet from './pages/UpdatePamphlet';
import ScrollToTop from './components/ScrollTop';
import ProfileUpdate from './pages/ProfileUpdate';

const Layout=()=>{
  return(
    <ScrollToTop>
    <Navbar/>
    <Outlet/>
    <Footer/>
    </ScrollToTop>
  )
}

const UserAuth =({children})=>{
  const userInfo = JSON.parse(localStorage.getItem('userData'));
  if(userInfo){
    const tokenExp = jwt_decode(userInfo?.token)
    const currentTime = Date.now() / 1000; // Convert milliseconds to seconds
    if(tokenExp.exp < currentTime){
      localStorage.removeItem('userData'); // Remove the expired token
      alert('Your session has expired. Please log in again.');
    }
  }
  if(!userInfo?.token){
    return <Navigate to="/login" />
  }
  return children;
}

const router = createBrowserRouter([
  {
    path: "/",
    element:<Layout/>,
    children:[
      {
        path: "/",
        element:<Home/>
      },
      {
        path: "/about",
        element:<AboutUs/>
      },
      {
        path: "/event/:id",
        element:<SpecificEvent/>
      },
      {
        path: "/allEvents",
        element:<AllEvents/>
      },
      {
        path: "/facilities",
        element:<Facilities/>
      },
      {
        path: "/admission",
        element:<Admission/>
      }
    ]
  },
  {
    path: "/login",
    element: <Login />
  },
  {
    path: "/dashboard",
    element:<UserAuth><Dashboard/></UserAuth>,
    children:[
      {
        path: "list/events",
        element:<ListEvents/>
      },
      {
        path: "create/events",
        element:<EventsUpdate/>
      },
      {
        path: "updatepamphlet",
        element:<UpdatePamphlet/>
      },
      {
        path: "profile",
        element:<ProfileUpdate/>
      }
    ]
  }
  // {
  //   path: "/register",
  //   element: <Registration />
  // }
]);

function App() {
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  ); 
}

export default App;
