import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
    return (
        <div style={{ backgroundColor: "#301515f0" }}>

            <footer className="text-center text-lg-start mt-5 text-white">

                <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">

                    <div className="me-5 d-none d-lg-block">
                        <span>Get connected with us on social networks:</span>
                    </div>

                    <div>
                        <a href="https://www.facebook.com/profile.php?id=100070042428023&mibextid=vk8aRt" className="me-4 text-reset" target='_blank'>
                            <i className="fab fa-facebook-f"></i>
                        </a>
                        {/* <a href="" className="me-4 text-reset">
                            <i className="fab fa-twitter"></i>
                        </a>
                        <a href="" className="me-4 text-reset">
                            <i className="fab fa-google"></i>
                        </a> */}
                        <a href="https://www.instagram.com/dhimanbharpursingh/?igsh=OGY3MTU3OGY1Mw%3D%3D" className="me-4 text-reset" target='_blank'>
                            <i className="fab fa-instagram"></i>
                        </a>
                        {/* <a href="" className="me-4 text-reset">
                            <i className="fab fa-linkedin"></i>
                        </a>
                        <a href="" className="me-4 text-reset">
                            <i className="fab fa-github"></i>
                        </a> */}
                    </div>

                </section>

                <section className="">
                    <div className="container text-center text-md-start mt-5">

                        <div className="row mt-3">

                            <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4" style={{width:"510px",height:"250px"}}>
                                <div className='googleMap'>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4060.795147049977!2d76.5643061185134!3d30.479749447866435!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390fdddaa2ad9caf%3A0xedbfd551f8b97fa2!2sB.S.B.D.S.M.P.%20SCHOOL!5e0!3m2!1sen!2sin!4v1702318911336!5m2!1sen!2sin" style={{border:'0'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                </div>
                            </div>

                            <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">

                                {/* <h6 className="text-uppercase fw-bold mb-4">
                                    Products
                                </h6>
                                <p>
                                    <a href="#!" className="text-reset">Angular</a>
                                </p>
                                <p>
                                    <a href="#!" className="text-reset">React</a>
                                </p>
                                <p>
                                    <a href="#!" className="text-reset">Vue</a>
                                </p>
                                <p>
                                    <a href="#!" className="text-reset">Laravel</a>
                                </p> */}
                            </div>

                            <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">

                                <h6 className="text-uppercase fw-bold mb-4">
                                    Useful links
                                </h6>
                                <p>
                                    <Link to="/" className="text-reset">Home</Link>
                                </p>
                                <p>
                                    <Link to="about" className="text-reset">About</Link>
                                </p>
                                <p>
                                    <Link to="allEvents" className="text-reset">All Events</Link>
                                </p>
                                <p>
                                    <Link to="facilities" className="text-reset">Facilities</Link>
                                </p>
                                <p>
                                    <Link to="admission" className="text-reset">Admission</Link>
                                </p>
                            </div>

                            <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">

                                <h6 className="text-uppercase fw-bold mb-4">Contact</h6>
                                <p><i className="fas fa-home me-3"></i> Near Karan Farms, Patiala Bye Pass, Rajpura (Pb.) 140 401</p>
                                <p>
                                    <i className="fas fa-envelope me-3"></i>
                                    <a className='anchorTag' href="mailto:bsbdsmpssschool@gmail.com">bsbdsmpssschool@gmail.com</a>
                                </p>
                                <p><i className="fas fa-phone me-3"></i>
                                <a className='anchorTag' href="tel:9463327041" style={{marginRight:"20px"}}>9463327041,</a>
                                <a className='anchorTag' href="tel:9463473363">9463473363</a>
                                 </p>
                                {/* <p><i className="fas fa-print me-3"></i> + 01 234 567 89</p> */}
                            </div>

                        </div>

                    </div>
                </section>

                <div className="text-center p-4" style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}>
                    © 2024 Copyright:
                    <a className="text-reset fw-bold" href="tel:7717431526">LoveTech Solutions</a>
                </div>

            </footer>
        </div>
    )
}

export default Footer