import { useState, React } from "react";
import { MyContext } from "./loginState";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
const APP_URL = process.env.REACT_APP_BACKEND_URL;

function LoginContext({children}) {
  const [userInfo, setUserInfo] = useState({});
  const navigate = useNavigate;
  const login = async(data)=>{
    try {
      const res = await axios.post(`${APP_URL}/school/login`,data);
      if (res.status == 200) {
        setUserInfo(res?.data.userData)
        localStorage.setItem('userData', JSON.stringify(res?.data.userData));
        setAuthorizationHeader(res?.data?.userData.token)
      }
      return res;
    } catch (error) {
      return error.response.data.message;
    }
  }

  const setAuthorizationHeader = (token) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  };

  return (
    <div>
      <MyContext.Provider value={{ userInfo, setUserInfo,login }}>
        {children}
      </MyContext.Provider>
    </div>
  );
}

export default LoginContext;