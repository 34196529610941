import ImageOverText from "../components/ImageOverText";
import LatestEvents from "../components/LatestEvents";
import MainCarousel from "../components/MainCarousel";
import WelcomeSection from "../components/WelcomeSection";
import YouTubeVid from "../components/YouTubeVid";

function Home() {
    return (
      <div className="homeContainer">
        <MainCarousel/>
        <WelcomeSection/>
        <LatestEvents/>
        <ImageOverText/>
        <YouTubeVid/>
      </div>
    );
  }
  
  export default Home;
  