import React from 'react'
import founderImg from '../assests/cofounder_bsbds.png'

function AboutDetails() {
    return (
        <div className='aboutDetailsContainer d-flex align-items-center container mt-4'>
            <div className='aboutDetails'>
                {/* <h3>Welcome to B.S.B.D.S.M.P. SCHOOL</h3> */}
                <p>
                    Respected Sant Maharaj Baba Variaam Singh Ji of Ratwara Sahib, while speaking to Sant Baba Gurdev Singh Ji, said, 'Brother Gurdev Singh Ji, go to Rajpura, spread the message, and start the temple of education.' Under the guidance of Sant Baba Gurdev Singh Ji, on December 9, 1999, a foundation stone was laid for Bhai Sahib Bhai Daya Singh Memorial Public School in Ratwara Sahib. The school officially started its first session on April 5, 2000. The first cultural program of the school was held on September 5, 2000. Sant Ji Maharaj Ratwara Sahib, along with the entire team of Ratwara Sahib Ji, attended all the programs. On December 5, 2000, a Gurmat program was conducted at the school. After watching the program, Sant Ji Maharaj Ratwara Sahib Ji said, 'You are working in the right direction,' and granted a sum of five lakh rupees to the school.
                </p>
                {/* <p>
                    In the second session, a cultural program was held on September 5, 2001. After the program, Sant Baba Variaam Singh Ji of Ratwara Sahib Ji called Baba Gurdev Singh Ji and Das (Bharpur Singh) and said, 'Son, now I am fully confident that you can run a very good school. I am happy now.' Das Bharpur Singh was also present. Sant Ji remarked, 'Run your own trust and make members who will support you. They will manage all the work of the schools on their own.' The second cultural program was held on September 5, 2001. The school had 135 students in the first session and 250 students in the second session. On February 25, 2002, Sant Baba Gurdev Singh Ji inaugurated Sant Baba Lakhveer Singh Ji Memorial Public School in Banur.
                </p> */}
            </div>
            <div className='about-detail-img'>
                <img className='rounded' src={founderImg} alt="" />
            </div>
        </div>
    )
}

export default AboutDetails